/* eslint-disable @next/next/no-img-element */
import React from "react";
import { useRouter } from "next/router";
import { XIcon, ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import { Image, Pressable, Modal } from "@gonoodle/gn-universe-ui";
import { SECTIONS_TYPES } from "@gonoodle/gn-universe-analytics-schema/src/constants";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";

import { useProfile, useOnSiteMessagesMutation } from "../../hooks";
import { useLogEvent } from "../../contexts/Analytics";
import {
  getSourcePageAndSourcePageTypeFromReferrer,
  getUtmParamsFromQueryParams,
} from "../../utils/analytics";
import Link from "../Link";
import { useUser } from "../../contexts/user";
import { useSDKStatus } from "../../contexts/OneTrust";
import { ROUTE_PREFIX, MESSAGE_TYPES, MESSAGE_STATUSES } from "../../constants";

function Growl({
  prompt,
  messageId,
  status,
  action = {},
  image,
  textTheme,
  onDismiss = () => {},
}) {
  const { updateMessagesStatus } = useOnSiteMessagesMutation();

  React.useEffect(() => {
    if (status === MESSAGE_STATUSES.UNSEEN) {
      updateMessagesStatus([
        { onSiteMessageId: messageId, action: MESSAGE_STATUSES.DISPLAYED },
      ]);
    }
  }, [messageId, status, updateMessagesStatus]);

  const dismissMessage = () => {
    updateMessagesStatus([
      { onSiteMessageId: messageId, action: MESSAGE_STATUSES.DISMISSED },
    ]);

    onDismiss();
  };

  return (
    <div className="isolate relative flex flex-col min-w-[320px] group">
      {action.url && (
        <Link to={action.url} className="absolute inset-0 z-10 outline-none" />
      )}

      <div className="relative h-[320px] overflow-hidden">
        <Image
          className="w-full h-full object-cover transform transition duration-200 group-hover:scale-110"
          sources={image}
          alt=""
        />

        <div className="absolute inset-0 bg-gradient-to-b from-[#18181B7A]" />

        <Pressable
          onPress={dismissMessage}
          className="absolute top-3 right-3 w-5 h-5 text-white z-20"
        >
          <XIcon />
        </Pressable>
      </div>

      <div
        className={twMerge(
          "flex flex-col mt-6 px-6 space-y-4",
          textTheme === "light" ? "text-white" : "text-gray-900",
        )}
      >
        <div className="text-[26px] leading-[20px] font-extrabold font-display">
          {prompt}
        </div>

        <div className="text-sm">{action.name}</div>
      </div>
    </div>
  );
}

function Growls({ growls = [], anchorBounds }) {
  const { isBlocked: isOneTrustBlocked, isBannerOpen: isOneTrustBannerOpen } =
    useSDKStatus();
  const { user } = useUser();
  const { hasSelectedProfile } = useProfile();
  // Avoids rendering from the growls array directly, so we can keep showing the growls even when there status changes to displayed.
  const [unseenGrowls, setUnseenGrowls] = React.useState(
    growls.filter(({ status }) => status === MESSAGE_STATUSES.UNSEEN),
  );
  const [currentGrowl, setCurrentGrowl] = React.useState(unseenGrowls[0]);
  const slideshowIntervalRef = React.useRef(null);

  const canShowGrowl =
    !(isOneTrustBlocked === false && isOneTrustBannerOpen) &&
    user.isLoggedIn &&
    hasSelectedProfile &&
    currentGrowl &&
    unseenGrowls.length > 0;

  React.useEffect(() => {
    const newUnSeenGrowls = growls.filter(
      ({ status }) => status === MESSAGE_STATUSES.UNSEEN,
    );

    setUnseenGrowls((prevGrowls) => {
      // In case one of the was deleted while the user was seeing it, for example when a stale tab is active again.
      const filteredPrevGrowls = prevGrowls.filter((prevGrowl) =>
        growls.some(
          (growl) => growl.onSiteMessageId === prevGrowl.onSiteMessageId,
        ),
      );

      const updatedGrowls = [
        ...filteredPrevGrowls,
        ...newUnSeenGrowls.filter(
          (newGrowl) =>
            !filteredPrevGrowls.some(
              (prevGrowl) =>
                prevGrowl.onSiteMessageId === newGrowl.onSiteMessageId,
            ),
        ),
      ];

      // Ensure currentGrowl is still valid, that case when a growl was deleted while the user was seeing it.
      if (
        currentGrowl &&
        !updatedGrowls.some(
          (growl) => growl.onSiteMessageId === currentGrowl.onSiteMessageId,
        )
      ) {
        setCurrentGrowl(updatedGrowls[0]); // Set to the first of the updated list if currentGrowl is no longer valid
      }

      return updatedGrowls;
    });
  }, [currentGrowl, growls]);

  React.useEffect(() => {
    if (!currentGrowl && unseenGrowls.length > 0) {
      setCurrentGrowl(unseenGrowls[0]);
    }
  }, [unseenGrowls, currentGrowl]);

  const navigateToGrowl = React.useCallback(
    (direction) => {
      const currentIndex = unseenGrowls.findIndex(
        (growl) => growl.onSiteMessageId === currentGrowl.onSiteMessageId,
      );
      let newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;

      // Wrap around the navigation
      if (newIndex >= unseenGrowls.length) newIndex = 0;
      if (newIndex < 0) newIndex = unseenGrowls.length - 1;

      setCurrentGrowl(unseenGrowls[newIndex]);
    },
    [currentGrowl, unseenGrowls],
  );

  const onDismiss = React.useCallback(() => {
    setCurrentGrowl(undefined);
    setUnseenGrowls([]);

    if (slideshowIntervalRef.current) {
      clearInterval(slideshowIntervalRef.current);
    }
  }, []);

  const startSlideshow = React.useCallback(() => {
    if (slideshowIntervalRef.current) {
      clearInterval(slideshowIntervalRef.current);
    }

    slideshowIntervalRef.current = setInterval(() => {
      navigateToGrowl("next");
    }, 5000); // change growl every 5 seconds
  }, [navigateToGrowl]);

  React.useEffect(() => {
    if (canShowGrowl && unseenGrowls.length > 1) {
      startSlideshow();
    }

    return () => {
      if (slideshowIntervalRef.current) {
        clearInterval(slideshowIntervalRef.current);
      }
    };
  }, [canShowGrowl, startSlideshow, unseenGrowls.length]);

  if (!canShowGrowl) {
    return null;
  }

  const currentGrowlIndex = unseenGrowls.findIndex(
    (growl) => growl.onSiteMessageId === currentGrowl.onSiteMessageId,
  );

  return (
    <Modal
      isOpen={true}
      isDismissable={true}
      className="justify-end items-start"
      onClose={onDismiss}
    >
      {/**
       * Removing pointer-events-none from the container to avoid breaking the modal click outside to close functionality,
       * as this container takes the whole screen and the modal is not able to detect the click outside.
       * TODO: Recreate the modal component to handle this case.
       */}
      <div className="absolute inset-0 container py-4 pointer-events-none">
        <div
          className="flex flex-col max-w-[320px] rounded-lg overflow-x-hidden mx-auto md:mx-0 md:ml-auto pointer-events-auto"
          style={{
            transform: `translate(0px, ${anchorBounds.bottom}px)`,
            backgroundColor: `#${currentGrowl.backgroundColor}`,
          }}
        >
          <motion.div
            initial={false}
            animate={{ x: `${currentGrowlIndex * -100}%` }}
            transition={{ duration: 0.7, ease: [0.32, 0.72, 0, 1] }}
            className="flex flex-row"
          >
            {unseenGrowls.map((growl) => (
              <Growl
                key={growl.id}
                messageId={growl.onSiteMessageId}
                prompt={growl.message}
                status={growl.status}
                action={{
                  name: growl.ctaPhrase,
                  url: growl.destinationUrl,
                }}
                image={growl.popoverDisplayImage}
                textTheme={growl.textTheme}
                onDismiss={onDismiss}
              />
            ))}
          </motion.div>

          {unseenGrowls.length > 1 && (
            <div className="flex flex-row justify-center items-center mt-4 w-full space-x-2">
              <Pressable
                className="outline-none"
                onPress={() => {
                  navigateToGrowl("previous");
                  startSlideshow(); // Restart the slideshow after manual navigation
                }}
              >
                <ArrowLeftIcon className="w-5 h-5 mr-4 text-gray-600 shrink-0" />
              </Pressable>

              {unseenGrowls.map((growl) => (
                <Pressable
                  key={growl.onSiteMessageId}
                  onPress={() => {
                    setCurrentGrowl(growl);
                    startSlideshow(); // Restart the slideshow after manual navigation
                  }}
                  className={twMerge(
                    "w-[10px] h-[10px] rounded-full shrink-0 transition-colors duration-700",
                    growl.onSiteMessageId === currentGrowl.onSiteMessageId
                      ? "bg-purple-500"
                      : "bg-gray-400",
                  )}
                />
              ))}

              <Pressable
                className="outline-none"
                onPress={() => {
                  navigateToGrowl("next");
                  startSlideshow(); // Restart the slideshow after manual navigation
                }}
              >
                <ArrowRightIcon className="ml-4 w-5 h-5 text-gray-600 shrink-0" />
              </Pressable>
            </div>
          )}

          <div className="mt-6" />
        </div>
      </div>
    </Modal>
  );
}

function Banner({
  prompt,
  action = {},
  color,
  sponsor = {},
  internalTitle,
  textTheme,
}) {
  const router = useRouter();
  const [isVisible, setIsVisible] = React.useState(true);
  const { profile } = useProfile();

  const analyticsEventProperties = {
    sourceElement: SECTIONS_TYPES.ANNOUNCEMENT_BANNER,
    sourceName: prompt,
    title: internalTitle.toLowerCase(),
    ...getSourcePageAndSourcePageTypeFromReferrer(
      router.pathname,
      router.query,
    ),
    ...getUtmParamsFromQueryParams(router.query),
    gradeId: profile?.grade.id,
    size: profile?.size,
    schoolId: profile?.school.id,
    url: action.url,
  };

  useLogEvent({
    event: "Announcement banner shown",
    properties: {
      ...analyticsEventProperties,
    },
    options: {
      enabled: !!prompt,
    },
  });

  return (
    <div
      className={twMerge(
        "flex-row justify-around px-4 py-3 md:px-6 md:py-4",
        textTheme === "light" ? "text-white" : "text-gray-900",
        isVisible ? "flex" : "hidden",
      )}
      style={{
        backgroundColor: `#${color}`,
      }}
    >
      <div className="flex-1 flex flex-col md:flex-row justify-center mr-4">
        <div>
          <span className="text-sm lg:text-md">{prompt}</span>

          {action.name && action.url && (
            <Link
              to={action.url}
              className="text-sm lg:text-md whitespace-nowrap underline ml-1"
              events={[
                {
                  event: "Announcement banner clicked",
                  properties: {
                    ...analyticsEventProperties,
                  },
                },
              ]}
              queryParams={{
                referrer: {
                  sourceElement: SECTIONS_TYPES.ANNOUNCEMENT_BANNER,
                  sourceName: action.name,
                  ...getSourcePageAndSourcePageTypeFromReferrer(
                    router.pathname,
                    router.query,
                  ),
                },
              }}
            >
              {action.name}
            </Link>
          )}
        </div>

        {sponsor.logo && (
          <div className="flex flex-row items-center">
            <div className="hidden md:block bg-purple-100 w-[1px] h-full rounded mx-6" />

            <span className="text-[10px] md:text-[11px] font-bold leading-6 whitespace-nowrap">
              Powered by
            </span>

            <span className="h-3 w-20 md:w-36 md:h-7">
              <Image
                className="w-full h-full object-contain"
                sources={sponsor.logo}
                alt={sponsor.name || ""}
              />
            </span>
          </div>
        )}
      </div>

      <Pressable
        onPress={() => setIsVisible(false)}
        className="w-6 h-6 self-center"
      >
        <XIcon />
      </Pressable>
    </div>
  );
}

export default function OnSiteMessages({ anchorBounds }) {
  const router = useRouter();
  const { user } = useUser();
  const { onSiteMessages = [] } = user;

  if (router.pathname.startsWith(`/${ROUTE_PREFIX.CURRICULUM}/`)) {
    return null;
  }

  const growls = onSiteMessages.filter(
    ({ displayType }) => displayType === MESSAGE_TYPES.GROWL,
  );
  const banner = onSiteMessages.find(
    ({ displayType }) => displayType === MESSAGE_TYPES.BANNER,
  );

  return (
    <>
      {banner && (
        <Banner
          prompt={banner.message}
          action={{
            name: banner.ctaPhrase,
            url: banner.destinationUrl,
          }}
          sponsor={{
            name: banner.logoAltText,
            logo: banner.logo,
          }}
          internalTitle={banner.internalTitle}
          textTheme={banner.textTheme}
          color={banner.backgroundColor}
        />
      )}

      <Growls growls={growls} anchorBounds={anchorBounds} />
    </>
  );
}
